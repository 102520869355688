import * as React from "react";

function Phone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.71 1.004A.999.999 0 013.8 1H7c1.433 0 2.837 1.012 2.996 2.6.086.868.346 1.66.648 2.568.418 1.136.156 2.36-.748 3.153l-.655.71a14.753 14.753 0 004.602 4.612l.75-.75a.99.99 0 01.067-.061c.805-.67 1.978-1.025 3.092-.569.899.3 1.687.556 2.548.642 1.588.159 2.6 1.563 2.6 2.995v3c0 1.652-1.347 3-3 3h-.2a.998.998 0 01-.096-.005c-3.274-.317-6.324-1.477-9.042-3.253a20.096 20.096 0 01-6.304-6.303C2.482 10.62 1.322 7.57 1.005 4.296v-.005C.857 2.664 2.037 1.156 3.71 1.004zM3.854 3c-.508.067-.907.548-.858 1.107.284 2.921 1.321 5.667 2.942 8.146l.01.017a18.095 18.095 0 005.683 5.682l.017.011c2.466 1.612 5.197 2.648 8.102 2.937h.15c.548 0 1-.452 1-1v-3c0-.568-.387-.964-.799-1.005-1.111-.111-2.101-.441-2.978-.733l-.039-.013a.985.985 0 01-.077-.03c-.267-.114-.659-.074-1.035.224l-1.264 1.264a1 1 0 01-1.196.166 16.72 16.72 0 01-6.384-6.384 1 1 0 01.138-1.167l1.2-1.3c.03-.032.061-.062.094-.09.247-.206.368-.545.204-.98a.983.983 0 01-.012-.036l-.013-.038C8.447 5.9 8.117 4.91 8.006 3.8 7.964 3.388 7.568 3 7 3H3.854z"
      />
    </svg>
  );
}

export default Phone;
