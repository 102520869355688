import * as React from "react";

function Email(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6l8 5 8-5v2a1 1 0 102 0V4c0-1.1-.9-2-2-2H3c-1.1 0-1.99.9-1.99 2L1 16c0 1.1.9 2 2 2h15.707l-2.146 2.146a1 1 0 001.414 1.415l3.905-3.906a1 1 0 00.009-1.423l-3.854-3.853a1 1 0 00-1.414 1.414L18.828 16H3V6zm16-2l-8 5-8-5h16z"
      />
    </svg>
  );
}

export default Email;
