import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import { graphql, useStaticQuery } from "gatsby";
import { DeepNonNullable } from "ts-essentials";
import { ContactDataQuery } from "../../graphqlTypes";
import { StaticImage } from "gatsby-plugin-image";
import Email from "../icons/Email";
import Phone from "../icons/Phone";
import Clipboard from "../icons/Clipboard";
import { removeWhitespaces } from "../utils/utils";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";

type ContactCardProps = {
  title: string;
  type?: "email" | "mobile" | "link";
  source: string;
  icon: JSX.Element;
};

const ContactCard = ({ title, source, icon, type }: ContactCardProps) => {
  let href: string = null!;

  switch (type) {
    case "email":
      href = `mailto:${source}`;
      break;
    case "mobile":
      href = `tel:${removeWhitespaces(source)}`;
      break;
    case "link":
      href = source;
      break;
    default:
      href = source;
  }

  return (
    <div className="max-w-min bg-accuRankerPurple-400 rounded-3xl border border-accuRankerPurpleOpacity-light flex flex-col justify-center items-center px-10 py-6">
      <div className="flex flex-col justify-center bg-white text-center bg-opacity-20 w-16 h-16 rounded-full mb-4">
        {icon}
      </div>
      <h3 className="text-white whitespace-nowrap">{title}</h3>
      <h3 className="text-accuRankerOrange-500">
        <a href={href}>{source.replace("https://www.", "")}</a>
      </h3>
    </div>
  );
};

const ContactIconClasses = {
  className: "text-accuRankerOrange-500 m-auto",
};

const ContactCardIcons: Pick<ContactCardProps, "icon" | "type">[] = [
  { icon: <Email {...ContactIconClasses} />, type: "email" },
  { icon: <Phone {...ContactIconClasses} />, type: "mobile" },
  { icon: <Clipboard {...ContactIconClasses} />, type: "link" },
];

const ContactPage = () => {
  const data = useStaticQuery<DeepNonNullable<ContactDataQuery>>(graphql`
    query ContactData {
      strapiContactpage {
        pageTitle
        childStrapiContactpageTitle {
          childMdx {
            body
          }
        }
        subtitle
        # Channels
        childStrapiContactpageChannelsTitle {
          childMdx {
            body
          }
        }
        Channels {
          title
          ContactCard {
            title
            source
          }
        }
      }
    }
  `);

  const {
    pageTitle,
    childStrapiContactpageTitle: title,
    subtitle,
    Channels,
    childStrapiContactpageChannelsTitle: channelsTitle,
  } = data.strapiContactpage;

  /** Channels is an array of "email", "phone" and "articles url" */
  const ChannelCards = Channels.ContactCard.map((channel, idx) => (
    <ContactCard {...channel} {...ContactCardIcons[idx]} />
  ));

  const linesClasses =
    "min-w-max my-auto rotate-90 xl:rotate-180 z-0 flex flex-col justify-center";

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block mt-20">
        <div className="flex flex-col items-start md:items-center text-center md:w-full md:p-20 md:pb-10 bg-accuRankerPurple-900">
          <div
            id="title"
            className="text-left md:text-center text-white text-2xl md:text-5xl font-medium mb-2"
          >
            <MDXRenderer>{mdxBody(title)}</MDXRenderer>
          </div>
          <div id="subtitle">
            <h2 className="text-left md:text-center text-white">{subtitle}</h2>
          </div>
        </div>
      </div>
      {/* Hubs Waterfall */}
      <div className="div-block mt-28 md:mt-0 md:px-20 md:pt-16 md:pb-20">
        <h2 className="text-left sm:text-center font-medium">
          <MDXRenderer>{mdxBody(channelsTitle)}</MDXRenderer>
        </h2>
        <div className="flex flex-col xl:flex-row mt-14 justify-center items-center">
          {ChannelCards[0]}
          <div className={linesClasses} style={{ height: "48px" }}>
            <StaticImage
              alt="lines"
              imgClassName="w-full m-auto z-10"
              className="m-auto"
              src="../images/Curves/ContactPage/Horizontal1.svg"
            />
          </div>
          {ChannelCards[1]}
          <div className={linesClasses} style={{ height: "48px" }}>
            <StaticImage
              alt="lines"
              imgClassName="w-full m-auto"
              src="../images/Curves/ContactPage/Horizontal2.svg"
            />
          </div>
          {ChannelCards[2]}
        </div>
        <div className="w-full text-center">
          <StaticImage
            alt="lines"
            imgClassName="w-full m-auto"
            src="../images/Curves/ContactPage/Vertical.svg"
          />
        </div>
      </div>

      <Footer />
    </Layout>
  );
};

export default ContactPage;
