import * as React from "react";

function Clipboard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8a1 1 0 01-1-1V5H5v14h14V5h-2v2a1 1 0 01-1 1H8zm8.5 13H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h4.18C9.6 1.84 10.7 1 12 1c1.3 0 2.4.84 2.82 2H19c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2h-2.5zM13 4c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zm-3 7a1 1 0 011-1h5a1 1 0 110 2h-5a1 1 0 01-1-1zm1 3a1 1 0 100 2h5a1 1 0 100-2h-5zm-2-3a1 1 0 11-2 0 1 1 0 012 0zm-1 5a1 1 0 100-2 1 1 0 000 2z"
      />
    </svg>
  );
}

export default Clipboard;
